@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');

/* @import url("//db.onlinewebfonts.com/c/7cc6719bd5f0310be3150ba33418e72e?family=Comic+Sans+MS"); */

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Lato',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",'Open Sans',
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input{
  font-family: 'Lato',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",'Open Sans',
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
button{
  font-family: 'Oswald',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",'Open Sans',
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}